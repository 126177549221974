<template>
  <!--
    Used to display and execute device-commands that should be available even when the device is not active.
  -->
  <div class="emergencyDeviceCommandDialog">
    <div
      v-for="(command, index) in commands"
      :key="`row${ index }`"
      class="row border-bottom p-2"
    >
      <div class="col-4">
        <button
          class="btn btn-secondary"
          @click="executeCommand(command)"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="command.icon"
            style="vertical-align: middle;"
          />{{ command.name }}
        </button>
      </div>
      <div class="col-1">
        {{ $t('emergencyDeviceCommandDialogView.tries') }}:
      </div>
      <div class="col-1">
        {{ command.tries }}
      </div>
      <div class="col-1">
        {{ $t('emergencyDeviceCommandDialogView.retries') }}:
      </div>
      <div class="col-5">
        <span class="progress w-100">
          <div
            v-if="(command.repetitions / repetitionMax) * 100 !== 0"
            :style="`width: ${ (command.repetitions / repetitionMax) * 100 }%;`"
            :aria-valuenow="(command.repetitions / repetitionMax) * 100"
            class="progress-bar bg-success"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          >{{ command.repetitions }} / {{ repetitionMax }}</div>
          <span v-else>{{ command.repetitions }} / {{ repetitionMax }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmergencyDeviceCommandDialog',
  props: {
    deviceId:{
      type: String,
      required: true
    }
  },
  data () {
    return {
      repetitionMax: 20,
      executing: false,
      response: null,
      commands: [
        {
          name: 'stopApplication',
          lastRequest: null,
          icon: 'ban',
          tries: 0,
          repetitions: 0
        },
        {
          name: 'reboot',
          lastRequest: null,
          icon: 'power-off',
          tries: 0,
          repetitions: 0
        }
      ],
      executeInterval: null,
      intervalRepetitions: 0,
      cancelSource: null
    }
  },
  beforeDestroy () {
    this.cancelRequest();
  },
  methods: {
    cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    },
    executeCommand (command) {
      if (command.repetitions >= this.repetitionMax) {
        command.tries++;
        command.repetitions = 0;
        return;
      }
      this.executing = true;
      command.lastRequest = Date.now();
      this.response = null;
      let commandRequest = {
        'Command': command.name,
        'Args': ''
      };
      this.cancelRequest();
      command.repetitions++;
      
      this.cancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSource.token };
      this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.deviceId }`, commandRequest, requestConfig)
        .then((response) => {
          if (response == null) {
            setTimeout(() => { this.executeCommand(command) }, 2000);
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.output == null) {
            return;
          }
          this.cancelSource = null;
          this.response = response.data.output;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.executing = false;
        });
    }
  }
}
</script>

<style scoped>
.progress {
  height: 40px;
  font-size: 1rem;
  line-height: 40px;
  position: relative;
}
.progress span {
  width: 100%;
  text-align: center;
  color: white;
}
</style>
